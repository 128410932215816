// only used for widget mode and standalone dev, in production fonts are bundled within cms
@font-face {
  font-family: 'Poppins';
  src: url('https://www.camperboerse.com/.resources/webresources/fonts/Poppins-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('https://www.camperboerse.com/.resources/webresources/fonts/Poppins-Bold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('https://www.camperboerse.com/.resources/webresources/fonts/Poppins-SemiBold.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
