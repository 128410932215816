@import './variables';

@mixin break($media, $type: min) {
  @media only screen and (#{$type}-width: $media) {
    @content;
  }
}

@mixin xxxlarge {
  @include break(map-get($grid-breakpoints, 'xxxl')) {
    @content;
  }
}

@mixin xxlarge {
  @include break(map-get($grid-breakpoints, 'xxl')) {
    @content;
  }
}

@mixin xlarge {
  @include break(map-get($grid-breakpoints, 'xl')) {
    @content;
  }
}

@mixin large {
  @include break(map-get($grid-breakpoints, 'lg')) {
    @content;
  }
}

@mixin medium {
  @include break(map-get($grid-breakpoints, 'md')) {
    @content;
  }
}

@mixin small {
  @include break(map-get($grid-breakpoints, 'sm')) {
    @content;
  }
}
